import React from "react"
import { graphql } from "gatsby"

import { StaticImage } from 'gatsby-plugin-image'

import '../../../../node_modules/slick-carousel/slick/slick.css'
import '../../../../node_modules/slick-carousel/slick/slick-theme.css'

import Layout from '../../../components/layout'
import SEO from '../../../components/seo'
import FeatureSide from '../../../components/feature-side'
import FeatureRelated from '../../../components/feature-related'

const Home = ({ data }) => {
  return (
    <Layout>
      <SEO
        pagetitle="インド独立の父、マハトマ・ガンジーの言葉から学ぼう！"
        pagepath="/feature/01/"
      />
      <main>
        <div className="l-feature__mv">
          <div className="l-feature__bg">
            <StaticImage
							src="../../../assets/images/feature/gandhi01.jpg"
							alt="ガンジー"
						/>
          </div>
          <div className="l-feature__above">
            <h3 className="l-feature__hdg">インド独立の父、マハトマ・ガンジーの言葉から学ぼう！</h3>
          </div>
        </div>
        <div className="l-feature">
          <div className="l-wrap">
            <div className="l-feature__item">
              <div className="l-feature__box">

                <div className="l-feature__main">

                  <div className="l-feature__trunk">
                    <div className="l-feature__paragraph">
                      <p>マハトマ・ガンジーにどんなイメージをお持ちでしょうか？</p>
                      <ul className="m-list">
                        <li><span className="em">聖人</span></li>
                        <li><span className="em">非暴力</span></li>
                        <li><span className="em">断食</span></li>
                      </ul>
                      <p>などが彼の特徴としてまず思い浮かぶことだと思います。</p>
                      <p>マハトマ・ガンジーの名言を紐解くと、<span className="marker-b">信念</span>と深い<span className="marker-b">叡智</span>、そして<span className="marker-b">強さ</span>が内在していたことを感じます。</p>
                      <p>わたしたちがまだ知らない、本当のマハトマ・ガンジーの魅力を名言とともにお届けします。</p>
                    </div>

                    <div className="l-feature__paragraph">
                      <h2>ガンジーの生い立ちと不服従運動</h2>
                      <p>本名、モハンダス･カラムチャンド･ガンディーは1869年10月2日にインド西端の地、インドグジャラート州ポールバンダルで生まれました。</p>
                      <p>晩年の品行方正な彼のイメージとは違い、幼い頃は素行が悪いことが知られています。ヒンドゥー教の戒律に背き、肉食を好み、幼少期からタバコにも手を出していました。</p>
                      <p>ガンジーが１８歳の頃、イギリスのロンドンに留学。弁護士になるための渡英でした。</p>
                      <p>弁護士資格を獲得し、インドへ帰国。母国で弁護士業を始めましたが、あまり業績はよくなく、当時同じくイギリスの支配下にあったイギリス領南アフリカ連邦（現在の南アフリカ共和国）に活路を見出しました。</p>
                      <p>南アフリカに渡った彼が直面したのは、白人優位の人種差別政策下でのインド系移民に対する人種差別でした。彼自身もさまざまな人種差別の被害に遭います。</p>
                      <p>青年期の神智学との出会いから、母国が持つ精神性の高さ・インド哲学などを継続的に学び続けていましたが、それは南アフリカに来てからも変わらずに続いていました。</p>
                      <p>インドが生んだ世界的宗教（ヒンドゥー教と仏教）には共に、『非暴力』の戒律があります。</p>
                      <p>後に、ガンジーが展開する『サティヤーグラハ』と呼ばれる「非暴力・不服従」の思想は、高い精神性を誇るインド文化をベースに編み出されたものです。</p>
                    </div>

                    <div className="l-feature__paragraph">
                      <h2>インド帰国</h2>
                      <p>1915年にインドに帰国したガンジー。</p>
                      <p>当時のインドはイギリスの植民地政策の支配下にありました。</p>
                      <p>第一次世界大戦後の自治権回復を期待し、最適な行動はイギリスを支援することだと考えました。</p>
                      <p>しかし、イギリスのインド植民地政府は「ローラット法」を制定します。</p>
                      <p>ローラット法とは、逮捕状がなくても逮捕が可能で、裁判をしなくても投獄が可能な権利をインド総督に認める法律です。</p>
                      <p>自身のイギリスに対する期待が裏切られ、また、自力での主権回復のみが、自治権を取り戻す手段だと確信した彼は、反英運動を展開します。</p>
                      <p>ガンジーは、インド独立を目指すインド国民会議に加わりました。</p>
                      <p>独立運動を主導し、イギリスの製造業者や、立法機関・教育機関などに対するボイコット運動を繰り広げました。</p>
                      <p>活動を扇動したとして、幾度となくガンジーは投獄・監禁されますが、彼の意志は変わりませんでした。</p>
                    </div>

                    <div className="l-feature__paragraph">
                      <h2>インド独立、そして暗殺</h2>
                      <p>第二次大戦の戦勝国になったイギリスですが、大戦により国力は低下し、インド植民地支配の継続が困難になりました。</p>
                      <p>そして、1947年8月15日、独立が達成されます。英領インド帝国は解体、インド連邦の独立が果たされました。</p>
                      <p>この独立により、インドとパキスタンは分離独立します。</p>
                      <p>ヒンドゥー教徒とイスラム教徒の対立は、ガンジーの望むところではなかったため、対立を留めるために彼は何度となく断食をしてアピールします。</p>
                      <p>平和への活動に休むことがなかったガンジー。</p>
                      <p>1948年1月30日、ニューデリーのビルラー邸で開かれた集会で、演台に向かっていたガンジーは、狂信的なヒンズー原理主義者によって3発の銃弾を撃ち込まれました。</p>
                      <p>薄れゆく意識の中で彼は「へー、ラーマ（おお、神よ）」とつぶやきました。</p>
                      <p>恐怖・憤怒・倒錯が彼を襲うことはなく、死の間際にも彼の精神性の高さと圧倒的な精神性が彼には生きていました。</p>
                      <p>こうして、ガンジーは障害を終えました。</p>
                      <p>彼が私達に残した功績は、計り知れません。</p>
                      <p>自らを律し、慈愛を世界に広げ、市の恐怖に打ち勝つ。</p>
                      <p>マハトマ（偉大なる魂）と呼ばれるにふさわしい彼を、世界中が賛嘆し続けることでしょう。</p>
                    </div>

                    <div className="l-feature__paragraph">
                      <h2>名言から紐解くマハトマ・ガンジー</h2>
                      <p>偉大なる魂、マハトマ・ガンジーはたくさんの名言を残しています。</p>
                      <p>その名言から彼の功績を振り返りましょう。</p>
                    </div>

                    <div className="l-feature__paragraph">
                      <div className="l-feature__wrap">
                        <div className="l-feature__num">01.</div>
                        <div className="l-feature__quote">
                          {data.post1.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">{node.title}</h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>

                        <p>ユーモアと非暴力・不服従が現れた言葉ですね。</p>
                        <p>目には目を、歯には歯を。</p>
                        <p>この言葉を実行することで、人類は幾度となく戦争という悲劇を繰り返してきました。</p>
                        <p>ガンジーは報復の無意味さを理解し、自ら先頭に立ち非暴力・不服従を推し進め人々の意識を改革しました。</p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">02.</div>
                        <div className="l-feature__quote">
                          {data.post2.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">{node.title}</h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>

                        <p>ガンジーが学び、実践し続けたインド哲学には「非暴力」という概念があります。</p>
                        <p>怒りは地獄に転生する因とされ、最も避けなければならない煩悩です。</p>
                        <p>どんなシチュエーションであっても、どんなに傷つけられても怒ってはない。</p>
                        <p>怒りは、連鎖し増幅します。</p>
                        <p>あなたが怒りをコントロールできれば、負の連鎖はとまります。</p>
                        <p>そして、怒りをコントロールすることは至難の業です。</p>
                        <p>怒りをコントロールできるのは強い人間のみです。</p>
                        <p>腕っぷしが強いこと、世俗的に成功できることは強さの証にはなりません。</p>
                        <p>怒りをコントロールできる心の広さ、器の大きさのみが強さの証明になるのです。</p>
                      </div>


                      <div className="l-feature__wrap">
                        <div className="l-feature__num">03.</div>
                        <div className="l-feature__quote">
                          {data.post3.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">{node.title}</h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>

                        <p>人間は、死刑囚と同じです。</p>
                        <p>なぜなら、生まれた瞬間に死が確定しているからです。</p>
                        <p>死がいつ、あなたを襲うか、誰にもわかりません。</p>
                        <p>もし明日死ぬと仮定すれば、あなたが抱えている今の悩みは解消するでしょう。</p>
                        <p>なぜなら私たちが抱える悩みというものは、生きることを前提とした悩みだからです</p>
                        <p>あなたが、ひとたび死ねば、金も名誉もプライドも何もかもを捨てて行かなくてはなりません。</p>
                        <p>そう考えれば、「死を考える」ことは強さの獲得と悩みからの解放を意味します。</p>
                        <p>ガンジーは常人では考えられないほどの強さと意志を発揮し、我々に生き様を教えてくれました。</p>
                        <p>彼の強さの源には、いつ死んでも構わないという死を覚悟した人間の力を感じます。</p>
                      </div>


                      <div className="l-feature__wrap">
                        <div className="l-feature__num">04.</div>
                        <div className="l-feature__quote">
                          {data.post4.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">{node.title}</h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>

                        <p>ガンジーほど意志が強く、人生を成し遂げてきた人が言うなら信じるしかないですね。</p>
                        <p>現代の脳科学でも、それは証明されています。</p>
                        <p>脳は、現実と想像の区別がつかないそうです。</p>
                        <p>つまり、日頃想像する自分の姿から、現実の自分が構成されていることになります。</p>
                        <p>ガンジーの持つ、計り知れない強さは日頃から彼の理想を考え続けてきた結果かもしれませんね。</p>
                      </div>

                      <div className="l-feature__wrap">

                        <div className="l-feature__num">05.</div>
                        <div className="l-feature__quote">
                          {data.post5.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">{node.title}</h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>

                        <p>自分の信じる正義。<span className="marker-b">自分が進みたい道。仕事・恋愛・人生。</span></p>
                        <p>わたしたちは理想を持ち、それを実行しようと試みます。</p>
                        <p>しかし、「常識」や「通例」、「慣習」という壁に阻まれ、その道を諦めます。</p>
                        <p>ガンジーのこの言葉を胸に、もう一度チャレンジしましょう。</p>
                        <p>死ぬ覚悟を持て！　というガンジーの別の名言と合わせて自分の信じる道を突き進みましょう。</p>
                        <p>大丈夫です。人間はどうせ死ぬのですから。</p>
                      </div>
                    </div>

                    <div className="l-feature__paragraph">
                      <h2>最後に</h2>
                      <p>インド独立の父、マハトマ・ガンジーの生涯と名言をご紹介しました。</p>
                      <p>彼の生き様、そして強靭な意志の力は現代を生きる私達の心にダイレクトに響いたと思います。</p>
                      <p>生きていれば、辛いことも悲しいこともあるのが当然です。</p>
                      <p>そんな障壁にぶち当たったとき、ガンジーは名言という形でわたしたちの背中を押してくれます。</p>
                      <p><span className="f-l">「大丈夫だ！　君の道を突き進め！」</span></p>
                      <p>ガンジーの声を心で聞いて、たくましく毎日を生きていきたいですね。</p>
                    </div>
                  </div>

                  <div className="l-feature__trunk">
                    <FeatureRelated />
                  </div>
                </div>

                <div className="l-feature__data">
                  <FeatureSide data={data} />
                </div>

              </div>

            </div>
          </div>
        </div>
      </main>
    </Layout>
  )
}

export const query = graphql`
  query {
    post1: allContentfulBlogPost(
      filter: {id: {eq: "e15f1f3f-f2a6-5ef7-b5e9-3478951a2bc7"}}
    ) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }

    post2: allContentfulBlogPost(
      filter: {id: {eq: "701fac6e-303b-5322-ab24-4f31db8af217"}}
    ) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }

    post3: allContentfulBlogPost(
      filter: {id: {eq: "807fd0b6-425a-501c-8d5d-edaf9ab1d915"}}
    ) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }

    post4: allContentfulBlogPost(
      filter: {id: {eq: "d5fd1833-ab72-5b70-b4f7-3a0b6c12f4b8"}}
    ) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }

    post5: allContentfulBlogPost(
      filter: {id: {eq: "52939aab-b6aa-5c6c-ba1e-81b04648de7c"}}
    ) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }

    cate: allContentfulCategory {
      edges {
        node {
          informations {
            informations
          }
          selfie {
            gatsbyImageData(layout: FULL_WIDTH)
            description
          }
        }
      }
    }

    allCate: allContentfulCategory(
      sort: {fields: sort, order: DESC},
      limit: 20
    ) {
      edges {
        node {
          name
          slug
          id
          selfie {
            gatsbyImageData(layout: FULL_WIDTH)
            description
          }
        }
      }
    }

    tags: allContentfulTags {
      edges {
        node {
          title
          slug
          id
        }
      }
    }
  }
`

export default Home